import React from "react";


const EodReports = ({eodReports}) => {
  
  
  return (
    <div className="bg-white p-4 rounded-b-xl">
    <p className="font-semibold text-2xl">EOD Reports</p>
    <div className="flex lg:flex-row flex-col flex-wrap w-full  mt-3 border-l-2 border-b-2">
      {eodReports[0] && Object.entries(eodReports[0]?.bse_info).filter(([key]) => key !== 'buy' && key !== 'sell' && key !== 'companyName')?.map(([key, item],i) => (
          <div key={i} className="flex gap-2 w-full lg:w-1/3 p-5 border-r-2 border-t-2 justify-between">
            <p>{key}</p>
            <p>{item}</p>
        </div>
      ))}
      
    </div>
    </div>
  );
};

export default EodReports;
