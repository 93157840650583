import { useState, useEffect } from "react";
import React from "react";
import { useLocation, Link } from "react-router-dom";
import factacy from "../../assets/factacy_logo.svg";


const SideBarLayoutPerson = () => {
    const [activePage, setActivePage] = useState("");

    const location = useLocation();
  
    useEffect(() => {
      // Extract the active page from the current location
      const pathSegments = location.pathname.split("/");
      const activePageFromPath = pathSegments[pathSegments.length - 1];
      setActivePage(activePageFromPath);
    }, [location]);
  
    return (
      <div className="w-1/5 lg:block sm:hidden">
        <div className="h-full flex flex-col">
          <div className="h-20"></div>
          <div
            className="bg-white mt-4 h-full"
            style={{
              boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
            }}
          >
            <ul className="flex flex-col">
              <li className="flex items-center px-2 py-3">
                <img src={factacy} className="w-9 h-9" />
                <p className="font-semibold text-lg">People Intelligence</p>
              </li>
              <Link
                to="/people_intelligence/person-search"
                title="Interactive Dashboard to View Investor Activity across Geographies, Sectors, Companies and more"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "person-search"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Person Search
              </Link>              
              <Link
                to="/people_intelligence/cohorts-alumni-connections"
                title="Interactive Dashboard to View Investor Activity across Geographies, Sectors, Companies and more"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "cohorts-alumni-connections"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Cohorts, Alumni and connections
              </Link>
              <Link
                to="/people_intelligence/contacts"
                title="Search an investor for its profile details, portfolio, news and more"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "contacts"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Contacts
              </Link>
              <Link
                to="/people_intelligence/exits"
                title="Gain insights into investor strategies by exploring sector-wise investor activity"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "exits"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Important exits and prior experience
              </Link>
              <Link
                to="/people_intelligence/inventors-innovators"
                title="Search for all the investment by a particular investor based on a geographical location"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "inventors-innovators"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Inventors and Innovators
              </Link>
              <Link
                to="/people_intelligence/key-people"
                title="Monitor investments by investors based Early Stage, Growth Scale and more"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "key-people"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                 Key People
              </Link>
              <Link
                to="/people_intelligence/outreach"
                title="Leaderboards for VCs, PEs, Accelerators & Incubators, Angel Networks, IBs, Single Family Offices, LPs, Debt Funds, Secondary Funds"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "outreach"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Send outreach
              </Link>
              {/* <Link
                to="/people_intelligence"
                title="Track and analyse shifts and trends in the investment landscape"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "Suggestions"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                 Innovations
              </Link>
              <Link
                to="/people_intelligence"
                title="Investment Opportunities for competing companies of particular investor"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "Sector wise"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                ALT Portfolio
              </Link>
              <Link
                to="/people_intelligence"
                title="Investor Matchmaking with a list of investor based on investee portfolio"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "Suggestions"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Potential investors
              </Link> */}
            </ul>
          </div>
        </div>
      </div>
    );
}

export default SideBarLayoutPerson