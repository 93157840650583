import React from 'react'
import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import factacy from "../../assets/factacy_logo.svg";


const SideBarLayoutPatent = () => {
    const [activePage, setActivePage] = useState("");

    const location = useLocation();
  
    useEffect(() => {
      // Extract the active page from the current location
      const pathSegments = location.pathname.split("/");
      const activePageFromPath = pathSegments[pathSegments.length - 1];
      setActivePage(activePageFromPath);
    }, [location]);
  
    
    return (
      <div className="w-1/5 sm:hidden lg:block">
        <div className="h-full flex flex-col">
          <div className="h-20"></div>
          <div className="bg-white mt-4 h-full flex flex-col gap-6">
            <ul className="flex flex-col">
              <li className="flex items-center justify-between px-2 py-3">
                <div className="flex items-center">
                  <img src={factacy} className="w-9 h-9" />
                  <p className="font-semibold text-lg">Innovations</p>
                </div>
                {/* <img src={filter} className='w-6 h-6' /> */}
              </li>
              <Link 
                title="Interactive Dashboard for all the deals as per sector, geography, investors and more"
                to= '/patent-intelligence/patent-search'
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "patent-search"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Search Patents
              </Link>

              <Link 
                title="Interactive Dashboard for all the deals as per sector, geography, investors and more"
                to= '/patent-intelligence/patent-dashboard'
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "patent-dashboard"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
               Patent Dash
              </Link>
              <Link 
                title="Detailed list of all the recent and historical deals"
                to= '/patent-intelligence/by-industry'
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "by-industry"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Patents by Industry
              </Link>
              <Link 
                title="Updates on latest and previous developments and deals of investors investing in companies- how much they have invested"
                to= '/patent-intelligence/by-company'
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "by-company"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Patents by Company
              </Link>
              <Link 
                title="Announcements by Companies who plan to raise funds"
                to= '/patent-intelligence/by-investor'
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "by-investor"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Patents by Investor
              </Link>
            </ul>
  
            <hr />
  
            <div>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SideBarLayoutPatent