import { useState, useEffect } from "react";
import React from "react";
import { useLocation, Link } from "react-router-dom";
import factacy from "../../assets/factacy_logo.svg";

const SideBarLayoutInvestor = () => {
  const [activePage, setActivePage] = useState("");

  const location = useLocation();

  useEffect(() => {
    // Extract the active page from the current location
    const pathSegments = location.pathname.split("/");
    const activePageFromPath = pathSegments[pathSegments.length - 1];
    setActivePage(activePageFromPath);
  }, [location]);

  return (
    <div className="w-1/5 lg:block sm:hidden">
      <div className="h-full flex flex-col">
        <div className="h-20"></div>
        <div
          className="bg-white mt-4 h-full rounded-tr-lg"
          style={{
            boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
          }}
        >
          <ul className="flex flex-col">
            <li className="flex items-center px-2 py-3">
              <img src={factacy} className="w-9 h-9" />
              <p className="font-semibold text-lg">Investor Intelligence</p>
            </li>
            <Link
              to="/investor_intelligence/investor-search"
              title="Interactive Dashboard to View Investor Activity across Geographies, Sectors, Companies and more"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "investor-search"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Investor Search
            </Link>
            <Link
              to="/investor_intelligence"
              title="Interactive Dashboard to View Investor Activity across Geographies, Sectors, Companies and more"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "investor_intelligence"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Investor Dash
            </Link>
            <Link
              to="/investor_intelligence/investor-watch"
              title="Search an investor for its profile details, portfolio, news and more"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "investor-watch"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Investor Watch
            </Link>
            <Link
              to="/investor_intelligence/investor-activity-sectorwise"
              title="Gain insights into investor strategies by exploring sector-wise investor activity"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "investor-activity-sectorwise"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Sector wise Investor Activity
            </Link>
            <Link
              to="/investor_intelligence/investor-activity-geowise"
              title="Search for all the investment by a particular investor based on a geographical location"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "investor-activity-geowise"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Geography wise Investor Activity
            </Link>
            <Link
              to="/investor_intelligence/investor-activity-stagewise"
              title="Monitor investments by investors based Early Stage, Growth Scale and more"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "investor-activity-stagewise"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Stage wise Investor Activity
            </Link>
            <Link
              to="/investor_intelligence/investor-leaderboard"
              title="Leaderboards for VCs, PEs, Accelerators & Incubators, Angel Networks, IBs, Single Family Offices, LPs, Debt Funds, Secondary Funds"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "investor-leaderboard"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              LeaderBoard
            </Link>
            <Link
              to="/investor_intelligence/shifts-trends"
              title="Track and analyse shifts and trends in the investment landscape"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "shifts-trends"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Investment shifts and trends
            </Link>
            <Link
              to="/investor_intelligence/alt-portfolio"
              title="Investment Opportunities for competing companies of particular investor"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "alt-portfolio"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              ALT Portfolio
            </Link>
            <Link
              to="/investor_intelligence/potential-investors"
              title="Investor Matchmaking with a list of investor based on investee portfolio"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "potential-investors"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Potential investors
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBarLayoutInvestor;
