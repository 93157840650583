import { useState, useEffect } from "react";
import React from "react";
import { useLocation, Link } from "react-router-dom";
import factacy from "../../assets/factacy_logo.svg";

const SideBarLayoutCompany = () => {
  const [activePage, setActivePage] = useState("");

  const location = useLocation();

  useEffect(() => {
    // Extract the active page from the current location
    const pathSegments = location.pathname.split("/");
    const activePageFromPath = pathSegments[pathSegments.length - 1];
    setActivePage(activePageFromPath);
  }, [location]);
  return (
    <div className="w-1/5 sm:hidden lg:block">
      <div className="h-full flex flex-col">
        <div className="h-20"></div>
        <div
          className="bg-white mt-4 h-full rounded-tr-xl"
          style={{
            boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
          }}
        >
          <ul className="flex flex-col">
            <li className="flex items-center px-2 py-3">
              <img src={factacy} className="w-9 h-9" />
              <p className="font-semibold text-lg">Company Intelligence</p>
            </li>
            <Link
              to="/company_intelligence/company-search"
              title="Interactive Dashboard to view company data as per geographies, sectors, scale and more"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "company-search"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Company Search
            </Link>
            <Link
              to="/company_intelligence/company-dashboard"
              title="Interactive Dashboard to view company data as per geographies, sectors, scale and more"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "company-dashboard"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Company Dash
            </Link>
            <Link
              to="/company_intelligence/company-watch"
              title="Search for a company by name for profile, financials, key people, patents and news"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "company-watch"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Company Watch
            </Link>
            <Link
              to="/company_intelligence/competitor-watch"
              title="User can Identify top local and global competitors of a specific company"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "competitor-watch"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Competitor Watch
            </Link>
            <Link
              to="/company_intelligence/company-leaderboard"
              title="Soonicorns, Unicorns and Decacorns in a leaderboard"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "company-leaderboard"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              LeaderBoard
            </Link>
            <Link
              to="/company_intelligence/product-service"
              title="Search for a companies by product or a service"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "product-service"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Companies by Product and Services
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBarLayoutCompany;
