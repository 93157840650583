import React, { useState } from "react";
import CurrentStage from "./Location/CurrentStage";
import City from "./Location/City";
import Country from "./Location/Country";
import arrow from "../../assets/arrow-drop-down-fill.svg";

const data = {
  "Most Used Filters": [
    {
      name: "Country",
      type: "country",
      component: <Country />
    },
    {
      name: "City",
      type: "city",
      component: <City />
    },
    {
      name: "Company Current Stage",
      type: "company_current_stage",
      component: <CurrentStage />
    },
    {
      name: "Founded Year",
      type: "founded_year"
    },
    {
      name: "Sectors (Practice Area & Feed)",
      type: "sector"
    },
    {
      name: "Special Flags (B2C, B2B, Tech, AI, etc)",
      type: "special_flag"
    },
    {
      name: "Business Model",
      type: "business_model"
    }
  ],
  "Company Location": [
    {
      name: "Country",
      type: "country"
    },
    {
      name: "State",
      type: "state"
    },
    {
      name: "City",
      type: "city"
    }
  ],
  "Company Details": [
    {
      name: "Company Name",
      type: "company_name"
    },
    {
      name: "Company Current Stage",
      type: "company_current_stage"
    },
    {
      name: "Founded Year",
      type: "founded_year"
    },
    {
      name: "Domain Extension",
      type: "domain_extension"
    },
    {
      name: "Company Keyword",
      type: "company_keyword"
    },
    {
      name: "Competitors Of",
      type: "competitors_of"
    },
    {
      name: "Similar Companies",
      type: "similar_companies"
    },
    {
      name: "Board Members",
      type: "board_members"
    }
  ],
  "Company Funding": [
    {
      name: "Total Funding",
      type: "total_funding"
    },
    {
      name: "Number of Funding Rounds",
      type: "number_of_funding_rounds"
    },
    {
      name: "Series Round",
      type: "series_round"
    },
    {
      name: "Round Amount",
      type: "round_amount"
    },
    {
      name: "Date of Round",
      type: "date_of_round"
    }
  ],
  "Company Sector": [
    {
      name: "Sectors",
      type: "sectors"
    },
    {
      name: "Special Flag",
      type: "special flag"
    }
  ],
  "Company Investors": [
    {
      name: "Company Investor",
      type: "company_investor"
    }
  ],
  "Company Analyst Rating": [
    {
      name: "Latest Unicorn and Soonicorn",
      type: "unicorn_and_soonicorn"
    }
  ],
  "Company Financials": [
    {
      name: "Latest Annual Revenue",
      type: "latest_annual_revenue"
    },
    {
      name: "Latest Net Profit",
      type: "latest_net_profit"
    },
    {
      name: "Latest Net Profit Margin",
      type: "latest_net_profit_margin"
    },
    {
      name: "Financial Publish Date",
      type: "financial_publish_date"
    }
  ]
};

const Main_Headings = ({setActiveComponent}) => {
  const [activeHeading, setActiveHeading] = useState([0]);
  const openHeading = (index) => {
    setActiveHeading((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((i) => i !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };

  const openComponent = (type) => {

  }

  return (
    <div className="w-60 overflow-auto h-full">
      {Object.entries(data).map(([item, val], i) => (
        <ul className="p-4 w-full">
          <li>
            <div
              key={i}
              onClick={() => {
                openHeading(i);
              }}
              className="flex justify-between w-full cursor-pointer"
            >
              <p>{item}</p>

              <img loading="lazy" alt="image" className="w-5" src={arrow} />
            </div>
            <ul className="grid overflow-hidden py-1 w-full transition-all duration-500 ease ">
              {activeHeading.includes(i) && (
                <>
                  {val?.map((subheading, i) => (
                    <li
                      className="text-xs  w-full transition-all duration-500 ease  max-h-fit"
                      key={i}
                    >
                      <p className={`w-full p-2 cursor-pointer hover:bg-gray-100`} onClick={() => setActiveComponent(subheading.type)}>
                        {subheading.name}
                      </p>
                    </li>
                  ))}
                </>
              )}
            </ul>
          </li>
        </ul>
      ))}
    </div>
  );
};

const Screening = () => {
  const [activeComponent, setActiveComponent] = useState("")

  return (
    <div className="fixed right-0 p-4 bg-white h-screen w-1/2 z-[100] shadow-2xl">
      <p className="text-lg font-semibold">Filter</p>
      <div className="w-full flex h-full">
        <Main_Headings activeComponent={activeComponent} setActiveComponent={setActiveComponent} />

        {activeComponent.includes}

      </div>
    </div>
  );
};

export default Screening;
