import { useState, useEffect } from "react";
import React from "react";
import { useLocation, Link } from "react-router-dom";
import factacy from "../../assets/factacy_logo.svg";

const SideBarLayoutLeaderboard = () => {
  const [activePage, setActivePage] = useState("");

  const location = useLocation();

  useEffect(() => {
    // Extract the active page from the current location
    const pathSegments = location.pathname.split("/");
    const activePageFromPath = pathSegments[pathSegments.length - 1];
    setActivePage(activePageFromPath);
  }, [location]);

  
  return (
    <div className="w-1/5 sm:hidden lg:block">
      <div className="h-full flex flex-col">
        <div className="h-20"></div>
        <div className="bg-white mt-4 h-full flex flex-col gap-6">
          <ul className="flex flex-col">
            <li className="flex items-center justify-between px-2 py-3">
              <div className="flex items-center">
                <img src={factacy} className="w-9 h-9" />
                <p className="font-semibold text-lg">Community Pages</p>
              </div>
              {/* <img src={filter} className='w-6 h-6' /> */}
            </li>
            <Link 
              title="Interactive Dashboard for all the deals as per sector, geography, investors and more"
              to= '/leaderboards/leading-investors'
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "leading-investors"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
               Leading Investors
            </Link>
            <Link 
              title="Detailed list of all the recent and historical deals"
              to= '/leaderboards/leading-sectors'
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "leading-sectors"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Leading Sectors
            </Link>
            <Link 
              title="Updates on latest and previous developments and deals of investors investing in companies- how much they have invested"
              to= '/leaderboards/decacorns'
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "decacorns"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Decacorns
            </Link>
            <Link 
              title="Announcements by Companies who plan to raise funds"
              to= '/leaderboards/unicorns'
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "unicorns"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Unicorns
            </Link>
            <Link 
              title="Competitors of a recent investee, who may be looking to raise funds"
              to= '/leaderboards/soonicorns'
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "soonicorns"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Soonicorns
            </Link>
            <Link 
              title="Shark Tank Featured Companies"
              to= '/leaderboards/shark-tank-companies'
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                activePage === "shark-tank-companies"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Shark Tank Companies
            </Link>
          </ul>

          <hr />

          <div>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBarLayoutLeaderboard