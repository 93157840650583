// import { useState, useEffect } from "react";
// import React from "react";
// import { useLocation, Link } from "react-router-dom";
// import factacy from "../../assets/factacy_logo.svg";

// const SideBarLayoutDeals = () => {
//   const [activePage, setActivePage] = useState("");

//   const location = useLocation();

//   useEffect(() => {
//     // Extract the active page from the current location
//     const pathSegments = location.pathname.split("/");
//     const activePageFromPath = pathSegments[pathSegments.length - 1];
//     setActivePage(activePageFromPath);
//   }, [location]);

  
//   return (
//     <div className="w-1/5 sm:hidden lg:block">
//       <div className="h-full flex flex-col">
//         <div className="h-20"></div>
//         <div className="bg-white mt-4 h-full flex flex-col gap-6">
//           <ul className="flex flex-col">
//             <li className="flex items-center justify-between px-2 py-3">
//               <div className="flex items-center">
//                 <img src={factacy} className="w-9 h-9" />
//                 <p className="font-semibold text-lg">Deals Intelligence</p>
//               </div>
//               {/* <img src={filter} className='w-6 h-6' /> */}
//             </li>
//             <Link 
//               title="Interactive Dashboard for all the deals as per sector, geography, investors and more"
//               to= '/deal-intelligence'
//               className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
//                 activePage === "deal-intelligence"
//                   ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
//                   : "text-[#4f4f53]"
//               } `}
//             >
//               Deal Dash
//             </Link>
//             <Link 
//               title="Detailed list of all the recent and historical deals"
//               to= '/deal-intelligence/deals'
//               className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
//                 activePage === "deals"
//                   ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
//                   : "text-[#4f4f53]"
//               } `}
//             >
//               Latest Deals
//             </Link>
//             <Link 
//               title="Detailed list of all the recent and historical deals"
//               to= '/deal-intelligence/historical-deals'
//               className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
//                 activePage === "historical-deals"
//                   ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
//                   : "text-[#4f4f53]"
//               } `}
//             >
//               Historical Deals
//             </Link>
//             <Link 
//               title="Updates on latest and previous developments and deals of investors investing in companies- how much they have invested"
//               to= '/deal-intelligence/current-previous-investors'
//               className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
//                 activePage === "current-previous-investors"
//                   ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
//                   : "text-[#4f4f53]"
//               } `}
//             >
//               Current and previous investors
//             </Link>
//             <Link 
//               title="Announcements by Companies who plan to raise funds"
//               to= '/deal-intelligence/open-deals'
//               className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
//                 activePage === "open-deals"
//                   ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
//                   : "text-[#4f4f53]"
//               } `}
//             >
//               Open deals
//             </Link>
//             <Link 
//               title="Competitors of a recent investee, who may be looking to raise funds"
//               to= '/deal-intelligence/alt-deals'
//               className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
//                 activePage === "alt-deals"
//                   ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
//                   : "text-[#4f4f53]"
//               } `}
//             >
//               ALT Deals
//             </Link>
//             <Link 
//               title="Deals with Exit information from previous investors"
//               to= '/deal-intelligence/exits'
//               className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
//                 activePage === "exits"
//                   ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
//                   : "text-[#4f4f53]"
//               } `}
//             >
//               Exits
//             </Link>
//             <Link 
//               title="Trends related to sectors, investors and investees"
//               to= '/deal-intelligence/investment-trends-themes'
//               className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
//                 activePage === "investment-trends-themes"
//                   ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
//                   : "text-[#4f4f53]"
//               } `}
//             >
//               Investment trends and themes
//             </Link>
//           </ul>

//           <hr />

//           <div>
//             <p></p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SideBarLayoutDeals;
