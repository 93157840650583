import { useState, useEffect } from "react";
import React from "react";
import { useLocation, Link } from "react-router-dom";
import factacy from "../../assets/factacy_logo.svg";

const SideBarLayoutSector = () => {
    const [activePage, setActivePage] = useState("");

    const location = useLocation();
  
    useEffect(() => {
      // Extract the active page from the current location
      const pathSegments = location.pathname.split("/");
      const activePageFromPath = pathSegments[pathSegments.length - 1];
      setActivePage(activePageFromPath);
    }, [location]);
  
    return (
      <div className="w-1/5 lg:block sm:hidden">
        <div className="h-full flex flex-col">
          <div className="h-20"></div>
          <div
            className="bg-white mt-4 h-full rounded-tr-lg"
            style={{
              boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
            }}
          >
            <ul className="flex flex-col">
              <li className="flex items-center px-2 py-3">
                <img src={factacy} className="w-9 h-9" />
                <p className="font-semibold text-lg">Sector Intelligence</p>
              </li>
              <Link
                to="/sector_intelligence/sector-search"
                title="Interactive Dashboard to View Investor Activity across Geographies, Sectors, Companies and more"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "sector-search"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Search Sector
              </Link>
              <Link
                to="/sector_intelligence/sector-dash"
                title="Interactive Dashboard to View Investor Activity across Geographies, Sectors, Companies and more"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "sector-dash"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                 Sector Dash
              </Link>
              <Link
                to="/sector_intelligence/sector-watch"
                title="Interactive Dashboard to View Investor Activity across Geographies, Sectors, Companies and more"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "sector-watch"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Sector Watch
              </Link>
              <Link
                to="/sector_intelligence/investors-sme"
                title="Search an investor for its profile details, portfolio, news and more"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "investors-sme"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Investors and SMEs
              </Link>
              <Link
                to="/sector_intelligence/relevant-companies"
                title="Gain insights into investor strategies by exploring sector-wise investor activity"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "relevant-companies"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Relevant companies
              </Link>
              <Link
                to="/sector_intelligence/clients-vendors"
                title="Search for all the investment by a particular investor based on a geographical location"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "clients-vendors"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Sector Clients and Vendors
              </Link>
              <Link
                to="/sector_intelligence/innovations"
                title="Monitor investments by investors based Early Stage, Growth Scale and more"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "innovations"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Sector Innovations
              </Link>
              <Link
                to="/sector_intelligence/thesis-reports"
                title="Leaderboards for VCs, PEs, Accelerators & Incubators, Angel Networks, IBs, Single Family Offices, LPs, Debt Funds, Secondary Funds"
                className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] px-5 py-3 ${
                  activePage === "thesis-reports"
                    ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                    : "text-[#4f4f53]"
                } `}
              >
                Sector thesis and reports
              </Link>
            </ul>
          </div>
        </div>
      </div>
    );
}

export default SideBarLayoutSector