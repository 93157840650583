import React from "react";
import Loader from "../tearSheet/loader";
import dropdown from "../../assets/arrow-drop-down-fill.svg";
import factacy from "../../assets/factacy_logo.svg";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const SidebarLayout = ({
  activeText,
  clickedText,
  handleTextClick,
  addToList,
  toggleShowIndustries,
  showIndustries,
}) => {
  const [activePage, setActivePage] = useState("");

  const location = useLocation();

  useEffect(() => {
    // Extract the active page from the current location
    const pathSegments = location.pathname.split("/");
    const activePageFromPath = pathSegments[pathSegments.length - 1];
    setActivePage(activePageFromPath);
  }, [location]);


  return (
    <div className="w-1/5 sm:hidden lg:block">
      <div className="h-full flex flex-col">
        <div className="h-20"></div>
        <div
          className="bg-white mt-4 h-full overflow-y-auto"
          style={{
            boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
          }}
        >
          <ul className="flex flex-col">
            <li className="flex items-center py-2 px-5">
              <img src={factacy} className="w-10 h-10" />
              <p className="font-semibold text-lg">News Intelligence</p>
            </li>
            <Link
              title="News Summaries for trending topics in the last 24 hours"
              to="/news-intelligence"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] py-3 px-5 ${
                activePage === "news-intelligence"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Hot Topics
            </Link>
            <Link
              title="Companies that are trending in the news"
              to="/news-intelligence/company"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] py-3 px-5 ${
                activePage === "company"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Companies in News
            </Link>
            <Link
              title="Funding raised, investments done, mergers and acquisitions done"
              to="/news-intelligence/deals"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] py-3 px-5 ${
                activePage === "deals"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Deals in News
            </Link>
            <Link
              title="News related to the funding rounds, mergers & acquisitions"
              to="/news-intelligence/funding"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] py-3 px-5 ${
                activePage === "funding"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Funding News
            </Link>
            {/* <Link
              title="News related to the financial activities of investors, investment decisions, strategies"
              to="/under-construction"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] py-3 px-5 ${
                activePage === ""
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              Investors in News
            </Link> */}
            {/* <Link
              title="People who are trending in a company or in news"
              to="/under-construction"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] py-3 px-5 ${
                activePage === ""
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              People in News
            </Link> */}
            <Link
              title="Trending and upcoming sectors appearing in the news"
              to="/news-intelligence/esg"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] py-3 px-5 ${
                activePage === "esg"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              ESG News
            </Link>
            {/* <Link
              title="News related to Environment, Social and Governance"
              to="/under-construction"
              className={`hover:text-primaryBlue cursor-pointer hover:bg-[#F4F5F7] py-3 px-5 ${
                activePage === ""
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              My News Feed
            </Link> */}
            <Link
              title="Create your own real-time and summarized news feed"
              to="/news-intelligence/sectors"
              className={`hover:text-primaryBlue cursor-pointer flex justify-between items-center hover:bg-[#F4F5F7] py-3 px-5 ${
                activePage === "sectors"
                  ? " text-primaryBlue font-semibold bg-[#F4F5F7]"
                  : "text-[#4f4f53]"
              } `}
            >
              <p>Sectors in News</p>
              <img
                src={dropdown}
                onClick={toggleShowIndustries}
                className="w-4 h-4"
              />
            </Link>
          </ul>

          {showIndustries ? (
            <Link
              to={`/news-intelligence/sectors/${clickedText}`}
              className="w-full sm:z-10 bg-white text-sm lg:animate-industryList sm:animate-navigationList pr-4 pl-10 text-[#4f4f53]"
            >
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Agriculture and Farming" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => (
                    // Promise.all([
                      handleTextClick("Agriculture and Farming"),
                      addToList(event)
                      
                    // ]);
          )}
                >
                  Agriculture and Farming
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Automotive" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Automotive"),
                    ]);
                  }}
                >
                  Automotive
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Aviation and Aerospace" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Aviation and Aerospace"),
                    ]);
                  }}
                >
                  Aviation and Aerospace
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Banking and Finance" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Banking and Finance"),
                    ]);
                  }}
                >
                  Banking and Finance
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "BioTechnology" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("BioTechnology"),
                    ]);
                  }}
                >
                  BioTechnology
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Business Services" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Business Services"),
                    ]);
                  }}
                >
                  Business Services
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Chemical Sector" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Chemical Sector"),
                    ]);
                  }}
                >
                  Chemical Sector
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Construction and Infrastructure"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Construction and Infrastructure"),
                    ]);
                  }}
                >
                  Construction and Infrastructure
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Consumer Electronics" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Consumer Electronics"),
                    ]);
                  }}
                >
                  Consumer Electronics
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Data and Analytics" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Data and Analytics"),
                    ]);
                  }}
                >
                  Data and Analytics
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "DeepTech" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("DeepTech"),
                    ]);
                  }}
                >
                  DeepTech
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Defense and Military" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Defense and Military"),
                    ]);
                  }}
                >
                  Defense and Military
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Education and Training" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Education and Training"),
                    ]);
                  }}
                >
                  Education and Training
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Electronic Manufacturing" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Electronic Manufacturing"),
                    ]);
                  }}
                >
                  Electronic Manufacturing
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Energy Sector" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Energy Sector"),
                    ]);
                  }}
                >
                  Energy Sector
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Environment tech" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Environment tech"),
                    ]);
                  }}
                >
                  Environment tech
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Food & Beverage" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Food & Beverage"),
                    ]);
                  }}
                >
                  Food & Beverage
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Food Tech" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Food Tech"),
                    ]);
                  }}
                >
                  Food Tech
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Gig Economy" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Gig Economy"),
                    ]);
                  }}
                >
                  Gig Economy
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "HealthCare & pharmaceuticals"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("HealthCare & pharmaceuticals"),
                    ]);
                  }}
                >
                  HealthCare & pharmaceuticals
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "HealthTech" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("HealthTech"),
                    ]);
                  }}
                >
                  HealthTech
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Hospitality and Tourism" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Hospitality and Tourism"),
                    ]);
                  }}
                >
                  Hospitality and Tourism
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Import & Export Sector" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Import & Export Sector"),
                    ]);
                  }}
                >
                  Import & Export Sector
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Industrial Manufacturing" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Industrial Manufacturing"),
                    ]);
                  }}
                >
                  Industrial Manufacturing
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Information Technology" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Information Technology"),
                    ]);
                  }}
                >
                  Information Technology
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Insurance" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Insurance"),
                    ]);
                  }}
                >
                  Insurance
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Internet and E-commerce" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Internet and E-commerce"),
                    ]);
                  }}
                >
                  Internet and E-commerce
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Journalism and Publishing"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Journalism and Publishing"),
                    ]);
                  }}
                >
                  Journalism and Publishing
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Legal and Law Enforcement"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Legal and Law Enforcement"),
                    ]);
                  }}
                >
                  Legal and Law Enforcement
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Life Science" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Life Science"),
                    ]);
                  }}
                >
                  Life Science
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Management and Consulting"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Management and Consulting"),
                    ]);
                  }}
                >
                  Management and Consulting
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Manufacturing" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Manufacturing"),
                    ]);
                  }}
                >
                  Manufacturing
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Media and Entertainment" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Media and Entertainment"),
                    ]);
                  }}
                >
                  Media and Entertainment
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Medical Devices" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Medical Devices"),
                    ]);
                  }}
                >
                  Medical Devices
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Mining and Metals" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Mining and Metals"),
                    ]);
                  }}
                >
                  Mining and Metals
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Nonprofit and Philanthropy"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Nonprofit and Philanthropy"),
                    ]);
                  }}
                >
                  Nonprofit and Philanthropy
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Oil Refining Sector" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Oil Refining Sector"),
                    ]);
                  }}
                >
                  Oil Refining Sector
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Online dating and matchmaking Tech"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Online dating and matchmaking Tech"),
                    ]);
                  }}
                >
                  Online dating and matchmaking Tech
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Others" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([addToList(event), handleTextClick("Others")]);
                  }}
                >
                  Others
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "personal care products and fashion tech"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick(
                        "personal care products and fashion tech"
                      ),
                    ]);
                  }}
                >
                  personal care products and fashion tech
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "ride hailing services" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("ride hailing services"),
                    ]);
                  }}
                >
                  ride hailing services
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Robotics and Automation" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Robotics and Automation"),
                    ]);
                  }}
                >
                  Robotics and Automation
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Saas Sector" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Saas Sector"),
                    ]);
                  }}
                >
                  Saas Sector
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "SeaFood Sector" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("SeaFood Sector"),
                    ]);
                  }}
                >
                  SeaFood Sector
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Service Industry" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Service Industry"),
                    ]);
                  }}
                >
                  Service Industry
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Sports and Recreation" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Sports and Recreation"),
                    ]);
                  }}
                >
                  Sports and Recreation
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "TechWear" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("TechWear"),
                    ]);
                  }}
                >
                  TechWear
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Telecommunication" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Telecommunication"),
                    ]);
                  }}
                >
                  Telecommunication
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Textiles and Apparel" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Textiles and Apparel"),
                    ]);
                  }}
                >
                  Textiles and Apparel
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Transportation and Logistics Tech"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Transportation and Logistics Tech"),
                    ]);
                  }}
                >
                  Transportation and Logistics Tech
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Veterinary activities" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Veterinary activities"),
                    ]);
                  }}
                >
                  Veterinary activities
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Waste Management and Recycling"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Waste Management and Recycling"),
                    ]);
                  }}
                >
                  Waste Management and Recycling
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Water and Sanitation" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Water and Sanitation"),
                    ]);
                  }}
                >
                  Water and Sanitation
                </p>
              </div>
              <div
                className={`flex gap-2 items-center  cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${
                  activeText === "Wholesale Trade & retail" ? "bg-gray-100" : ""
                }`}
              >
                <p
                  onClick={(event) => {
                    Promise.all([
                      addToList(event),
                      handleTextClick("Wholesale Trade & retail"),
                    ]);
                  }}
                >
                  Wholesale Trade & retail
                </p>
              </div>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default SidebarLayout;
